import { FC, useRef, Ref, createContext, PropsWithChildren } from "react";

// Inner imports
import { TableContent } from "./types";

type TableContext = {
  contentRef: Ref<TableContent>;
};

export const TableContext = createContext<TableContext>({
  contentRef: null,
});

export const TableContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const contentRef = useRef<TableContent>(null);

  return (
    <TableContext.Provider
      value={{
        contentRef,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
