import {
  useRef,
  useMemo,
  useState,
  Fragment,
  forwardRef,
  useCallback,
  ForwardedRef,
  useLayoutEffect,
} from "react";
import cx from "classnames";
import isString from "lodash/isString";
import { useTranslation } from "react-i18next";
import useResizeObserver from "use-resize-observer";

import styles from "./MenuDropdown.module.scss";
import { ThreeDots } from "src/assets/icons";
import { useOutsideClickHandler } from "src/hooks";
import { getIconByName, isAppIconTypeGuard, sortValues } from "src/utils";

// Inner imports
import { MenuDropdownSubmenu } from "./components";
import type { DropdownDirection, MenuDropdownProps, MenuOption } from "./types";
import {
  getClientParams,
  getElementParams,
  updateFixedDropdownPosition,
} from "./utils";

export const MenuDropdown = forwardRef(
  (
    {
      options,
      children,
      buttonContent,
      className = "",
      dropdownDirection,
      buttonClassName = "",
      dropdownClassName = "",
      isPositionFixed = false,
      isEventPropagationStopped,
      hasSingleOptionAsSubmenu = false,
      ...props
    }: MenuDropdownProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const { t } = useTranslation();

    const buttonRef = useRef<HTMLButtonElement>(null);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const dropdownButtonRef = useRef<HTMLDivElement>(null);

    const { ref: resizeCallbackRef } = useResizeObserver<HTMLDivElement>({
      ref: dropdownRef,
    });

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [openingDirection, setOpeningDirection] = useState<DropdownDirection>(
      dropdownDirection ?? { x: undefined, y: undefined },
    );

    const toggleOpenMenu = useCallback(
      (): void => setIsOpen((state) => !state),
      [],
    );

    const MenuDropdownButton = useMemo<JSX.Element>(() => {
      if (children) return <div onClick={toggleOpenMenu}>{children}</div>;

      if (buttonContent)
        return (
          <button
            type="button"
            onClick={toggleOpenMenu}
            className={cx(styles.button, buttonClassName)}
            ref={buttonRef}
          >
            {buttonContent}
          </button>
        );

      return (
        <button
          type="button"
          onClick={toggleOpenMenu}
          className={cx(styles.button, buttonClassName)}
          ref={buttonRef}
        >
          <ThreeDots
            className={cx(
              styles.buttonIcon,
              isOpen ? styles.buttonIconRotate : "",
            )}
          />
        </button>
      );
    }, [buttonClassName, buttonContent, children, isOpen, toggleOpenMenu]);

    const formattedOptions = useMemo<MenuOption[][]>(() => {
      const formattedOptions = new Map<MenuOption["group"], MenuOption[]>();

      for (const option of options) {
        const { group = 1 } = option;

        if (!formattedOptions.has(group)) formattedOptions.set(group, []);

        formattedOptions.get(group)?.push(option);
      }

      const groups = Array.from(formattedOptions.keys()).sort((a, b) =>
        sortValues(a, b, "ASC"),
      );

      return groups.map((group) => formattedOptions.get(group) || []);
    }, [options]);

    const renderOption = useCallback(
      (option: MenuOption, index: number): JSX.Element => {
        const subOptionsLength = option.options?.length || 0;

        const {
          icon,
          label,
          onClick,
          disabled,
          type = "default",
          responsivenessType = "",
        } = option;

        if (subOptionsLength)
          return (
            <MenuDropdownSubmenu
              key={`${label}-${index}`}
              option={option}
              direction={dropdownDirection?.x}
              callback={() => setIsOpen(false)}
              hasSingleOptionAsSubmenu={hasSingleOptionAsSubmenu}
              className={cx(styles.option, styles[responsivenessType])}
            />
          );

        const Icon = isAppIconTypeGuard(icon) ? getIconByName(icon) : icon;

        const title = isString(label) ? label : "";

        return (
          <button
            key={`${label}-${index}`}
            type="button"
            className={cx(
              styles.option,
              styles[type],
              styles[responsivenessType],
            )}
            onClick={(e) => {
              onClick?.(e);
              setIsOpen(false);
            }}
            disabled={disabled}
          >
            {Icon && <div className={styles.icon}>{Icon}</div>}
            <span className={styles.label} title={title}>
              {label}
            </span>
          </button>
        );
      },
      [dropdownDirection?.x, hasSingleOptionAsSubmenu],
    );

    const callbackRef = (element: HTMLDivElement | null): void => {
      if (!buttonRef.current || element === null) return;

      if (isPositionFixed)
        updateFixedDropdownPosition(buttonRef.current, element);

      resizeCallbackRef(element);
    };

    useLayoutEffect(() => {
      if (dropdownDirection) return;

      const calculateOpeningDirection = () => {
        const {
          element: parentElement,
          right: parentRight,
          bottom: parentBottom,
        } = getElementParams(ref);

        const {
          height: dropdownHeight,
          width: dropdownWidth,
          right: dropdownRight,
          bottom: dropdownBottom,
        } = getElementParams(dropdownRef);

        const {
          height: dropdownButtonHeight,
          width: dropdownButtonWidth,
          right: dropdownButtonRight,
          bottom: dropdownButtonBottom,
        } = getElementParams(dropdownButtonRef);

        const { height, width } = getClientParams();

        if (!dropdownHeight || !dropdownWidth)
          return setOpeningDirection({
            x: undefined,
            y: undefined,
          });

        if (parentElement)
          return setOpeningDirection({
            x: parentRight > dropdownRight ? "right" : "left",
            y: parentBottom > dropdownBottom ? "down" : "up",
          });

        return setOpeningDirection({
          x:
            width > dropdownButtonRight + dropdownButtonWidth
              ? "right"
              : "left",
          y:
            height > dropdownButtonBottom + dropdownButtonHeight
              ? "down"
              : "up",
        });
      };

      calculateOpeningDirection();

      window.addEventListener("resize", calculateOpeningDirection);

      return () =>
        window.removeEventListener("resize", calculateOpeningDirection);
    }, [isOpen, dropdownDirection, ref]);

    useOutsideClickHandler(dropdownButtonRef, () => setIsOpen(false));

    return (
      <div
        ref={dropdownButtonRef}
        className={cx(styles.menuDropdown, className)}
        onClick={(e) => isEventPropagationStopped && e.stopPropagation()}
        {...props}
      >
        {MenuDropdownButton}
        {isOpen && (
          <div
            className={cx(
              styles.dropdown,
              styles[openingDirection?.x || ""],
              styles[openingDirection?.y || ""],
              dropdownClassName,
            )}
            ref={callbackRef}
          >
            {Boolean(formattedOptions.length) ? (
              formattedOptions.map((group, index) => (
                <Fragment key={index}>
                  {group.map(renderOption)}
                  <div className={styles.divider} />
                </Fragment>
              ))
            ) : (
              <div className={styles.noOptions}>
                <span className={styles.label}>{t("no_options")}</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
);
