import { FC, useMemo } from "react";

import styles from "./SuggestedCategory.module.scss";
import { ChevronRight } from "src/assets/icons";
import { TRACKER_CATEGORY_ICON } from "src/constants";
import { Button, ContrastText } from "src/components";
import { formatTrackerCategoryKey, getIconByName } from "src/utils";

type Props = {
  category: Tracker.Category;
  isLoading: boolean;
  selectedCategory: Tracker.Category | null;
  selectCategoryHandler: (value: Tracker.Category) => void;
};

export const SuggestedCategory: FC<Props> = ({
  category,
  isLoading,
  selectedCategory,
  selectCategoryHandler,
}) => {
  const categoryIcon = useMemo<JSX.Element | null>(() => {
    const icon = TRACKER_CATEGORY_ICON[category.category.toLowerCase()];

    if (!icon) return getIconByName(TRACKER_CATEGORY_ICON.default);

    return getIconByName(icon);
  }, [category.category]);

  const isDisabled = useMemo<boolean>(
    () => isLoading && selectedCategory !== category,
    [category, isLoading, selectedCategory],
  );

  return (
    <Button
      key={formatTrackerCategoryKey(category)}
      onClick={() => selectCategoryHandler(category)}
      disabled={isDisabled}
      className={styles.wrapper}
      buttonStyle="clear"
    >
      <ContrastText className={styles.icon}>{categoryIcon}</ContrastText>
      <div className={styles.content}>
        <div className={styles.heading}>
          <span className={styles.name}>{category.subject}</span>
          <span className={styles.category}>{category.category}</span>
        </div>
        <span className={styles.description}>{category.description}</span>
      </div>
      <ChevronRight className={styles.arrowIcon} />
    </Button>
  );
};
