import * as yup from "yup";
import mapValues from "lodash/mapValues";

import {
  WIDGET_IDS,
  DASHBOARD_VISIBILITY,
  DASHBOARD_LAYOUT_VIEWS,
} from "src/constants";

const gridItemSchema = yup
  .object({
    widgetId: yup
      .string<Widget.IdType>()
      .oneOf([...WIDGET_IDS])
      .required(),
    xAxis: yup.number().required(),
    yAxis: yup.number().required(),
    width: yup.number().required(),
    height: yup.number().required(),
  })
  .required()
  .noUnknown();

export const dashboardTilesSchema = yup.lazy((obj) =>
  yup
    .object(mapValues(obj, () => yup.boolean<true>().oneOf([true]).required()))
    .required(),
);

export const dashboardLayoutsSchema = yup
  .object({
    small: yup
      .array(gridItemSchema.required())
      .transform((value) => value || [])
      .required(),
    medium: yup
      .array(gridItemSchema.required())
      .transform((value) => value || [])
      .required(),
    large: yup
      .array(gridItemSchema.required())
      .transform((value) => value || [])
      .required(),
  })
  .required()
  .noUnknown();

const widgetSettingsSchema = yup.lazy((obj) =>
  yup
    .object(
      mapValues(obj, () =>
        yup.object({
          name: yup.string().default(""),
        }),
      ),
    )
    .default(null)
    .nullable()
    .noUnknown(),
);

const dashboardSettingsSchema = yup
  .object({
    widgets: widgetSettingsSchema,
  })
  .default(null)
  .nullable()
  .noUnknown();

export const dashboardSchema = yup
  .object({
    authorId: yup.string().required(),
    companyId: yup.string().required(),
    name: yup
      .string()
      .transform((x) => (x ? x : "Nameless Dashboard"))
      .default("Nameless Dashboard"),
    trackersCollectionId: yup.string().required(),
    originalDashboardId: yup.string().nullable().default(null),
    visibility: yup
      .string<Dashboard.Visibility>()
      .oneOf([...DASHBOARD_VISIBILITY])
      .required(),
    tiles: dashboardTilesSchema,
    layouts: dashboardLayoutsSchema,
    layoutView: yup
      .string<Dashboard.LayoutView>()
      .oneOf([...DASHBOARD_LAYOUT_VIEWS])
      .default("full"),
    settings: dashboardSettingsSchema,
    description: yup.string().default(""),
    createdAt: yup.string().default(""),
    updatedAt: yup.string().default(""),
    isLocked: yup.boolean().default(false),
  })
  .noUnknown();

export const suggestedDashboardTrackerSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().default(""),
    category: yup.string().required(),
    locations: yup.array(yup.string().required()).required(),
    searchesCount: yup.number().required(),
  })
  .required()
  .noUnknown();

export const suggestedDashboardSchema = yup
  .object({
    authorId: yup.string().required(),
    companyId: yup.string().required(),
    dashboardId: yup.string().required(),

    authorFirstName: yup.string().default(""),
    authorLastName: yup.string().default(""),

    name: yup.string().required(),
    visibility: yup
      .string<Dashboard.Visibility>()
      .oneOf([...DASHBOARD_VISIBILITY])
      .required(),

    trackers: yup.array(suggestedDashboardTrackerSchema).required(),

    score: yup.number().required(),
  })
  .required()
  .noUnknown();

export const createDashboardWithTrackersSchema = yup
  .object({
    dashboardId: yup.string().required(),
    trackerIds: yup.array(yup.string().required()).required(),
  })
  .required()
  .noUnknown();

export const suggestedDashboardsSchema = yup
  .array(suggestedDashboardSchema)
  .required();

export const suggestedQueriesSchema = yup
  .object({
    examples: yup.array(yup.string().required()).required(),
  })
  .required()
  .noUnknown();

export const getDashboardDataSchema = yup
  .object({
    eventIds: yup.array(yup.string().required()).required(),
    trackerIds: yup.array(yup.string().required()).required(),
    dashboardDateRangeIds: yup.array(yup.string().required()).required(),
  })
  .required()
  .noUnknown();

export const generateDashboardShareLinkTokenSchema = yup
  .object({
    token: yup.string().required(),
  })
  .required()
  .noUnknown();

export const parseDashboardShareLinkTokenSchema = yup
  .object({
    dashboardId: yup.string().required(),
    dashboardDateRangeId: yup.string().required(),
    isUpdateAllowed: yup.boolean().required(),
  })
  .required()
  .noUnknown();

const duplicateDashboardSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    trackerIds: yup.array(yup.string().required()).required(),
  })
  .required()
  .noUnknown();

export const duplicateDashboardsSchema = yup.object({
  dashboards: yup.array(duplicateDashboardSchema).required(),
});

export type CreateDashboardWithTrackersSchemaType = yup.InferType<
  typeof createDashboardWithTrackersSchema
>;
