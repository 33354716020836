import { getElementContrastedColor } from "src/utils";

export const handleTextColor = ({
  element,
  updateHandler,
}: {
  element?: HTMLButtonElement | null;
  updateHandler: (value: string) => void;
}): void => {
  if (!element) return;

  const newFontColor = getElementContrastedColor({
    element,
    minRatio: 3.8,
  });

  updateHandler(newFontColor);
};
