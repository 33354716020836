import { RootState } from "src/store";

import { selectUserId } from "../user/userSelector";
import { selectCompanyId } from "../company/companySelector";
import { selectWidgetsInfo } from "../widgetsInfo/widgetsInfoSelectors";
import { selectWhiteLabel } from "../whiteLabel/whiteLabelSelector";
import { selectSubscriptionPlanAvailableWidgetIds } from "../subscriptionPlans/subscriptionPlansSelector";

import { getTimestamps } from "src/store/utils";
import { isWidgetIdTypeGuard } from "src/utils";

// Dashboard payload creation functions ---->
export const createDashboardPayload = (
  state: RootState,
  dataForCreation: Dashboard.DataForCreation,
): Store.CreateEntity<Dashboard.Data> => {
  const widgetsInfo = selectWidgetsInfo(state);

  const availableWidgetIds = selectSubscriptionPlanAvailableWidgetIds(state);

  const { defaultDashboard: whiteLabelDefaultDashboard } =
    selectWhiteLabel(state);

  const coreDashboardData = generateDashboardCoreData(state, dataForCreation);

  const { layouts, tiles } = getDashboardDefaultLayoutSettings({
    widgetsInfo,
    availableWidgetIds,
    whiteLabelDefaultDashboard,
  });

  return {
    ...coreDashboardData,
    tiles,
    layouts,
  };
};

function generateDashboardCoreData(
  state: RootState,
  dataForCreation: Dashboard.DataForCreation,
): Omit<Dashboard.Data, "id" | "tiles" | "layouts"> {
  // Core data includes all main info about new dashboard.
  // Except the data about dashboard such as TILES and LAYOUTS.

  const companyId = selectCompanyId(state);
  const userId = selectUserId(state);

  const { trackersCollectionId, ...dataForCreationRest } = dataForCreation;

  const { createdAt, updatedAt } = getTimestamps();

  return {
    ...dataForCreationRest,
    trackersCollectionId,
    authorId: userId,
    layoutView: "full",
    companyId,
    createdAt,
    updatedAt,
    settings: null,
    isLocked: false,
  };
}

export function getDashboardDefaultLayoutSettings({
  widgetsInfo,
  availableWidgetIds,
  whiteLabelDefaultDashboard,
}: {
  widgetsInfo: WidgetsInfo.Data[];
  availableWidgetIds: Widget.IdType[];
  whiteLabelDefaultDashboard: WhiteLabel.DefaultDashboard;
}): Pick<Dashboard.Data, "layouts" | "tiles"> {
  const dashboardWidgetIds = getDashboardDefaultAvailableWidgetIds({
    widgetsInfo,
    availableWidgetIds,
    whiteLabelDefaultDashboard,
  });

  return {
    layouts: getDashboardDefaultLayouts(whiteLabelDefaultDashboard),
    tiles: getDashboardDefaultTiles(dashboardWidgetIds),
  };
}

function getDashboardDefaultAvailableWidgetIds({
  widgetsInfo,
  availableWidgetIds,
  whiteLabelDefaultDashboard,
}: {
  widgetsInfo: WidgetsInfo.Data[];
  availableWidgetIds: Widget.IdType[];
  whiteLabelDefaultDashboard: WhiteLabel.DefaultDashboard;
}): Widget.IdType[] {
  const whiteLabelWidgetIds = new Set<Widget.IdType>();

  for (const widgetId in whiteLabelDefaultDashboard.tiles)
    if (isWidgetIdTypeGuard(widgetId)) whiteLabelWidgetIds.add(widgetId);

  const widgetIds = new Set<Widget.IdType>();

  for (const widget of widgetsInfo) {
    const { id, isActive } = widget;

    const isWidgetAvailableInSP = availableWidgetIds.includes(id);
    const isWidgetAddedInitially = whiteLabelWidgetIds.has(id);

    if (isWidgetAddedInitially && isActive && isWidgetAvailableInSP)
      widgetIds.add(id);
  }

  if (!availableWidgetIds.length)
    throw "We cannot create a dashboard such as no widgets are available to you";

  return [...widgetIds];
}

function getDashboardDefaultTiles(
  availableWidgetIds: Widget.IdType[],
): Dashboard.Data["tiles"] {
  const trackerTile: Dashboard.Tiles = {};

  for (const availableWidget of availableWidgetIds)
    trackerTile[availableWidget] = true;

  return trackerTile;
}

function getDashboardDefaultLayouts(
  whiteLabelDefaultDashboard: WhiteLabel.DefaultDashboard,
): Dashboard.Data["layouts"] {
  return whiteLabelDefaultDashboard.layouts;
}

// function getDashboardDefaultLayouts(
//   availableWidgetIds: Widget.IdType[],
//   isDefault = false,
// ): Dashboard.Data["layouts"] {
//   if (isDefault) return DASHBOARD_DEFAULT_LAYOUT;
//
//   return {
//     small: createLayout(availableWidgetIds, "small"),
//     medium: createLayout(availableWidgetIds, "medium"),
//     large: createLayout(availableWidgetIds, "large"),
//   };
// }

// function createLayout(
//   widgetList: Widget.IdType[],
//   layoutSize: "large" | "medium" | "small",
// ): Dashboard.GridItem[] {
//   return widgetList.reduce<Dashboard.GridItem[]>((acc, widgetId, index) => {
//     const isOdd = index % 2 === 1;
//
//     const standardValues: Pick<
//       Dashboard.GridItem,
//       "widgetId" | "height" | "width"
//     > = {
//       ...WIDGET_DIMENSIONS[layoutSize],
//       widgetId,
//     };
//
//     const axis = {
//       small: {
//         xAxis: 0,
//         yAxis: index,
//       },
//       medium: {
//         xAxis: isOdd ? 1 : 0,
//         yAxis: Math.floor(index / 2),
//       },
//       large: {
//         xAxis: isOdd ? 2 : 0,
//         yAxis: Math.floor(index / 2),
//       },
//     };
//
//     acc.push({
//       ...standardValues,
//       ...axis[layoutSize],
//     });
//
//     return acc;
//   }, []);
// }
