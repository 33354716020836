import { FC, useMemo } from "react";

import styles from "./SelectedCategory.module.scss";
import { getIconByName } from "src/utils";
import { ContrastText } from "src/components";
import { TRACKER_CATEGORY_ICON } from "src/constants";

type Props = {
  category: Tracker.Category;
};

export const SelectedCategory: FC<Props> = ({ category }) => {
  const categoryIcon = useMemo<JSX.Element | null>(() => {
    const icon = TRACKER_CATEGORY_ICON[category.category.toLowerCase()];

    if (!icon) return getIconByName(TRACKER_CATEGORY_ICON.default);

    return getIconByName(icon);
  }, [category.category]);

  return (
    <div className={styles.wrapper}>
      <ContrastText className={styles.icon}>{categoryIcon}</ContrastText>
      <div className={styles.content}>
        <div className={styles.heading}>
          <span className={styles.name}>{category.subject}</span>
          <span className={styles.category}>{category.category}</span>
        </div>
        <span className={styles.description}>{category.description}</span>
      </div>
    </div>
  );
};
