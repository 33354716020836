import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./app/App";
import store from "./store";
import ContextProvider from "./context/ContextProvider";

import "rsuite/dist/rsuite-no-reset.min.css";
import "./index.scss";

import "src/services/firebase";
import "src/services/i18nLocalization";
import "src/services/googleTagManager";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <ContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ContextProvider>
  </Provider>,
);
