import { FC, useMemo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./DashboardDateRanges.module.scss";
import { useModal } from "src/hooks";
import { TimerOutline } from "src/assets/icons";
import { Button, Select, Tooltip } from "src/components";
import { DASHBOARD_DATE_RANGE_LABEL } from "src/constants";
import { CreateDashboardDateRangeModal } from "src/features";
import { SelectOption } from "src/components/selectors/Select/types";
import { formatToMonthFullYearDate, updateQueryParams } from "src/utils";
import {
  selectHasDashboardAccess,
  selectIsCompanyDashboard,
  selectEventsByDashboardId,
  selectDefaultDashboardDateRangeByTrackersCollectionId,
  selectActiveDashboardDateRangesByTrackersCollectionId,
} from "src/store/selectors";

// Inner imports
import { sortDashboardDateRanges } from "./utils";
import { DashboardDateRangeActions } from "..";

type Props = {
  isReadOnly: boolean;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  selectDashboardDateRangeIdHandler: (
    value: DashboardDateRange.Data["id"],
  ) => void;
};

export const DashboardDateRanges: FC<Props> = ({
  isReadOnly,
  trackersCollectionId,
  dashboardDateRangeId,
  selectDashboardDateRangeIdHandler,
}) => {
  const history = useHistory();

  const { t } = useTranslation();

  const { setModal } = useModal();

  const { hasDashboardAccess } = useSelector(selectHasDashboardAccess);

  const isCompanyDashboard = useSelector((state: Store.RootState) =>
    selectIsCompanyDashboard(state, trackersCollectionId),
  );

  const dashboardDateRanges = useSelector((state: Store.RootState) =>
    selectActiveDashboardDateRangesByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const defaultDashboardDateRange = useSelector((state: Store.RootState) =>
    selectDefaultDashboardDateRangeByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const events = useSelector((state: Store.RootState) =>
    selectEventsByDashboardId(state, trackersCollectionId),
  );

  const sortedDashboardDateRanges = useMemo<DashboardDateRange.Data[]>(
    () => [...dashboardDateRanges].sort(sortDashboardDateRanges),
    [dashboardDateRanges],
  );

  const isAddDashboardDateRangeButtonShown = useMemo<boolean>(
    () => isCompanyDashboard && !isReadOnly && hasDashboardAccess,
    [isCompanyDashboard, isReadOnly, hasDashboardAccess],
  );

  const formatDashboardDateRangeLabel = useCallback(
    (dashboardDateRange: DashboardDateRange.Data): string => {
      if (dashboardDateRange?.type)
        return DASHBOARD_DATE_RANGE_LABEL[dashboardDateRange.type];

      if (dashboardDateRange?.startDate && dashboardDateRange?.endDate) {
        const [formattedStartDate, formattedEndDate] = [
          formatToMonthFullYearDate(new Date(dashboardDateRange.startDate)),
          formatToMonthFullYearDate(new Date(dashboardDateRange.endDate)),
        ];

        return t("page.dashboard.label.dashboard_date_range", {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
      }

      return "";
    },
    [t],
  );

  const dashboardDateRangeOptions = useMemo<SelectOption[]>(
    () =>
      sortedDashboardDateRanges.map((dashboardDateRange) => {
        const dashboardDateRangeEvent = events.find(
          ({ dashboardDateRangeId }) =>
            dashboardDateRangeId === dashboardDateRange.id,
        );

        return {
          value: dashboardDateRange.id,
          label: formatDashboardDateRangeLabel(dashboardDateRange),
          ...(dashboardDateRangeEvent
            ? {
                renderIcon: () => (
                  <Tooltip
                    content={t(
                      "page.dashboard.tooltip.dashboard_date_range_event",
                      { name: dashboardDateRangeEvent?.name },
                    )}
                  >
                    <TimerOutline />
                  </Tooltip>
                ),
              }
            : {}),
          ...(dashboardDateRange.category === "custom" && !isReadOnly
            ? {
                renderActions: () => (
                  <DashboardDateRangeActions
                    dashboardDateRange={dashboardDateRange}
                  />
                ),
              }
            : {}),
        };
      }),
    [
      t,
      events,
      isReadOnly,
      sortedDashboardDateRanges,
      formatDashboardDateRangeLabel,
    ],
  );

  useEffect(() => {
    if (!defaultDashboardDateRange) return;

    const dashboardDateRange = dashboardDateRanges.find(
      ({ id }) => dashboardDateRangeId === id,
    );

    if (!dashboardDateRange)
      selectDashboardDateRangeIdHandler(defaultDashboardDateRange.id);
  }, [
    dashboardDateRanges,
    dashboardDateRangeId,
    defaultDashboardDateRange,
    selectDashboardDateRangeIdHandler,
  ]);

  const openCreateDashboardDateRangeModal = useCallback(
    (outerCallback: () => void): void => {
      const callback = (
        dashboardDateRangeId: DashboardDateRange.Data["id"],
      ): void => {
        updateQueryParams({ dateRangeId: dashboardDateRangeId }, history);

        outerCallback();
      };

      setModal(
        "create-dashboard-date-range",
        <CreateDashboardDateRangeModal
          callback={callback}
          trackersCollectionId={trackersCollectionId}
        />,
      );
    },
    [history, setModal, trackersCollectionId],
  );

  const renderCreateDashboardDateRangeForm = useCallback(
    (callback: () => void): JSX.Element | null => {
      if (!isAddDashboardDateRangeButtonShown) return null;

      return (
        <div className={styles.dropdownFooterWrapper}>
          <Button
            buttonSize="small"
            className={styles.button}
            onClick={() => openCreateDashboardDateRangeModal(callback)}
            buttonStyle="transparent"
          >
            {t("page.dashboard.button.create_dashboard_date_range")}
          </Button>
        </div>
      );
    },
    [t, openCreateDashboardDateRangeModal, isAddDashboardDateRangeButtonShown],
  );

  const selectIcon = useMemo<AppIcon>(() => {
    const isEventDashboardDateRange = events.find(
      (event) => event.dashboardDateRangeId === dashboardDateRangeId,
    );

    if (isEventDashboardDateRange) return "TimerOutline";

    return "CalendarRange";
  }, [dashboardDateRangeId, events]);

  return (
    <div className={styles.wrapper}>
      <Select
        icon={selectIcon}
        hasFilter={false}
        value={dashboardDateRangeId}
        openingDirection="bottom-end"
        inputClassName={styles.input}
        selectClassName={styles.select}
        dropdownClassName={styles.dropdown}
        options={dashboardDateRangeOptions}
        optionsClassName={styles.optionsWrapper}
        renderFooter={renderCreateDashboardDateRangeForm}
        changeHandler={selectDashboardDateRangeIdHandler}
      />
    </div>
  );
};
