import { FC, HTMLAttributes, useCallback, useState } from "react";

// Inner imports
import { handleTextColor } from "./utils";

type Props = HTMLAttributes<HTMLSpanElement>;

export const ContrastText: FC<Props> = (props) => {
  const [fontColor, setFontColor] = useState<string>("");

  const refCallback = useCallback((element: HTMLButtonElement | null): void => {
    handleTextColor({ element, updateHandler: setFontColor });
  }, []);

  return (
    <span
      {...props}
      ref={refCallback}
      style={{ color: fontColor, ...props.style }}
    />
  );
};
