import {
  ReactNode,
  CSSProperties,
  isValidElement,
  MutableRefObject,
} from "react";
import { AxiosError, CanceledError } from "axios";
import isNull from "lodash/isNull";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import isBoolean from "lodash/isBoolean";
import firebase from "firebase/compat/app";
import isUndefined from "lodash/isUndefined";
import { FirebaseError } from "@firebase/util";

import * as icons from "src/assets/icons";
import firebaseAuth from "src/services/firebaseAuth";
import {
  WIDGET_IDS,
  EVENT_TYPES,
  DASHBOARD_VISIBILITY,
  SEARCH_KEYWORDS_DATA_SOURCES,
} from "src/constants";

export const isErrorTypeGuard = (value: unknown): value is Error => {
  if (value instanceof Error) return true;

  return (
    isObject(value) && "message" in value && typeof value.message === "string"
  );
};

export const isWidgetIdTypeGuard = (value: string): value is Widget.IdType =>
  [...WIDGET_IDS].includes(value as Widget.IdType);

export const isCSSPropertyTypeGuard = (
  value: string,
  styles: CSSProperties,
): value is keyof CSSProperties => value in styles;

export const isDashboardVisibilityTypeGuard = (
  value: string,
): value is Dashboard.Visibility =>
  [...DASHBOARD_VISIBILITY].includes(value as Dashboard.Visibility);

export const isSearchCreatedTypeGuard = (
  search: Search.Data | Search.CreationData,
): search is Search.Data => "id" in search && "createdAt" in search;

export const isEventTypeTypeGuard = (value: string): value is Event.Type =>
  [...EVENT_TYPES].includes(value as Event.Type);

export const isEventTargetNodeGuard = (
  target: EventTarget | null,
): target is Node => Boolean(target && "nodeType" in target);

export const isAxiosErrorTypeGuard = (value: unknown): value is AxiosError =>
  value instanceof AxiosError;

export const isKeywordsDataSourceTypeGuard = (
  value: unknown,
): value is Search.KeywordsDataSource =>
  [...SEARCH_KEYWORDS_DATA_SOURCES].includes(
    value as Search.KeywordsDataSource,
  );

export const isAppIconTypeGuard = (value: unknown): value is AppIcon =>
  typeof value === "string" && Object.keys(icons).includes(value);

export const isFirebaseErrorTypeGuard = (
  value: unknown,
): value is firebase.FirebaseError => value instanceof FirebaseError;

export const isFirebaseAuthErrorTypeGuard = (
  value: unknown,
): value is firebaseAuth.AuthError =>
  isFirebaseErrorTypeGuard(value) && value.code.startsWith("auth/");

export const isInputElementTypeGuard = (
  value: HTMLElement,
): value is HTMLInputElement | HTMLTextAreaElement =>
  value instanceof HTMLInputElement || value instanceof HTMLTextAreaElement;

export const isAbortedRequestErrorTypeGuard = (
  value: unknown,
): value is CanceledError<unknown> =>
  value instanceof CanceledError && Boolean(value.config?.signal);

export const isKeyboardEventTypeGuard = (
  value: unknown,
): value is KeyboardEvent => value instanceof KeyboardEvent;

export const isMutableRefTypeGuard = <T>(
  value: unknown,
): value is MutableRefObject<T> =>
  value !== null && isObject(value) && "current" in value;

export const isValidNodeTypeGuard = (value: unknown): value is ReactNode =>
  isValidElement(value) ||
  isUndefined(value) ||
  isNull(value) ||
  isBoolean(value) ||
  isString(value) ||
  isNumber(value) ||
  isArray(value);
