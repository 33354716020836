import { FC, useContext, useMemo, useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  stringFilter,
  showToastNotification,
  getDifferenceInObjects,
} from "src/utils";

import styles from "./EventsSection.module.scss";
import { useModal } from "src/hooks";
import { useAppDispatch } from "src/store";
import { CreateEventModal, EditEventModal } from "src/features";
import { Button, InputWithIcon, Tooltip, Translation } from "src/components";
import { DashboardPageContext } from "src/pages/Dashboards/DashboardPageWrapper/context";
import {
  selectEventsLimit,
  selectEventsByDashboardId,
} from "src/store/selectors";
import {
  removeEvents,
  updateEvents,
  removeDashboardDateRanges,
} from "src/store/actions";
import {
  Loader,
  Pencil,
  TrashFill,
  PlusCircleFill,
  MinusCircleFill,
  PlusCircleOutline,
} from "src/assets/icons";

type Props = {
  updatedEvents: Event.Data[];
  removedEvents: Event.Data[];
  dashboardId: Dashboard.Data["id"];
  removeEventHandler: (id: Event.Data["id"]) => void;
  updateEventHandler: (
    id: Event.Data["id"],
    changes: Partial<Event.Data>,
  ) => void;
};

export const EventsSection: FC<Props> = ({
  dashboardId,
  updatedEvents,
  removedEvents,
  updateEventHandler,
  removeEventHandler,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { setModal } = useModal();

  const { closeSidebar } = useContext(DashboardPageContext);

  const [filterValue, setFilterValue] = useState<string>("");

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const events = useSelector((state: Store.RootState) =>
    selectEventsByDashboardId(state, dashboardId),
  );

  const { isEventsLimitExceeded, eventsLimit } = useSelector(selectEventsLimit);

  const filteredEvents = useMemo<Event.Data[]>(
    () => updatedEvents.filter(({ name }) => stringFilter(name, filterValue)),
    [updatedEvents, filterValue],
  );

  const eventsUpdates = useMemo<Store.UpdateEntity<Event.Data>[]>(() => {
    const updates: Store.UpdateEntity<Event.Data>[] = [];

    for (const event of events) {
      const updatedEvent = updatedEvents.find(({ id }) => id === event.id);

      if (!updatedEvent) continue;

      const difference = getDifferenceInObjects(updatedEvent, event, {
        ignoredKeys: ["updatedAt"],
      });

      if (difference) updates.push({ id: event.id, changes: difference });
    }

    return updates;
  }, [events, updatedEvents]);

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isSubmitAvailable = useMemo<boolean>(() => {
    if (isLoading) return false;

    if (updatedEvents.length !== events.length) return true;

    return Boolean(eventsUpdates.length);
  }, [events.length, eventsUpdates.length, isLoading, updatedEvents.length]);

  const hasEvents = useMemo<boolean>(
    () => Boolean(filteredEvents.length),
    [filteredEvents.length],
  );

  const onNewEventClick = (): void => {
    if (isEventsLimitExceeded)
      return showToastNotification({
        id: "event-limit",
        type: "warning",
        text: (
          <Translation
            i18nKey="event.status.warning.event_limit"
            values={{ count: eventsLimit }}
          />
        ),
      });

    setModal("create-event", <CreateEventModal dashboardId={dashboardId} />);
  };

  const onEditEventClick = (eventId: Event.Data["id"]): void =>
    setModal(
      "edit-event",
      <EditEventModal dashboardId={dashboardId} eventId={eventId} />,
    );

  const onToggleActiveEventClick = (event: Event.Data): void =>
    updateEventHandler(event.id, {
      isActive: !event.isActive,
    });

  const onRemoveEventClick = (eventId: Event.Data["id"]): void =>
    removeEventHandler(eventId);

  const onSubmit = async (): Promise<void> => {
    const [removedEventIds, removedDashboardDateRangeIds] = [
      new Set<Event.Data["id"]>(),
      new Set<DashboardDateRange.Data["id"]>(),
    ];

    for (const { id, dashboardDateRangeId } of removedEvents) {
      removedEventIds.add(id);

      if (dashboardDateRangeId)
        removedDashboardDateRangeIds.add(dashboardDateRangeId);
    }

    try {
      setLoadingStatus("loading");

      const promises: Promise<unknown>[] = [];

      if (removedEventIds.size)
        promises.push(
          dispatch(removeEvents(Array.from(removedEventIds))).unwrap(),
        );

      if (removedDashboardDateRangeIds.size)
        promises.push(
          dispatch(
            removeDashboardDateRanges(Array.from(removedDashboardDateRangeIds)),
          ).unwrap(),
        );

      if (eventsUpdates.length)
        promises.push(dispatch(updateEvents(eventsUpdates)).unwrap());

      await Promise.all(promises);

      setLoadingStatus("succeeded");

      showToastNotification({
        id: "dashboard_events_update",
        type: "success",
        text: t(
          "component.sidebar.dashboard_events.status.success.events_updated",
        ),
      });

      closeSidebar();
    } catch (error) {
      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      setLoadingStatus("failed");
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.filterWrapper}>
        <InputWithIcon
          icon="Search"
          className={styles.filterInput}
          value={filterValue}
          changeHandler={setFilterValue}
          hasClearButton
          placeholder={t(
            "component.sidebar.dashboard_events.form.placeholder.search_query",
          )}
        />
      </div>
      <div className={styles.eventsWrapper}>
        <div className={styles.eventsTitle}>
          <span>{t("component.sidebar.dashboard_events.title")}</span>
          <div className={styles.eventsTitleActions}>
            <Tooltip
              content={t(
                "component.sidebar.dashboard_events.tooltip.create_event",
              )}
            >
              <Button
                className={styles.eventButton}
                buttonSize="small"
                buttonStyle="transparent"
                onClick={onNewEventClick}
              >
                <PlusCircleFill />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={styles.events}>
          {!hasEvents && (
            <div className={styles.placeholder}>
              {t("component.sidebar.dashboard_events.label.no_events")}
            </div>
          )}
          {hasEvents &&
            filteredEvents.map((event) => (
              <div
                key={event.id}
                className={cx(
                  styles.event,
                  styles[event.isActive ? "selected" : ""],
                )}
              >
                <span>{event.name}</span>
                <div className={styles.eventButtons}>
                  <Tooltip
                    content={
                      event.isActive
                        ? t(
                            "component.sidebar.dashboard_events.button.deactivate",
                          )
                        : t(
                            "component.sidebar.dashboard_events.button.activate",
                          )
                    }
                  >
                    <Button
                      className={styles.eventButton}
                      buttonSize="small"
                      buttonStyle="transparent"
                      onClick={() => onToggleActiveEventClick(event)}
                    >
                      {event.isActive ? (
                        <MinusCircleFill />
                      ) : (
                        <PlusCircleOutline />
                      )}
                    </Button>
                  </Tooltip>
                  <Tooltip
                    content={t(
                      "component.sidebar.dashboard_events.button.edit",
                    )}
                  >
                    <Button
                      className={styles.eventButton}
                      buttonSize="small"
                      buttonStyle="transparent"
                      onClick={() => onEditEventClick(event.id)}
                    >
                      <Pencil />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    content={t(
                      "component.sidebar.dashboard_events.button.remove",
                    )}
                  >
                    <Button
                      className={styles.eventButton}
                      buttonSize="small"
                      buttonStyle="transparent"
                      onClick={() => onRemoveEventClick(event.id)}
                    >
                      <TrashFill />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          buttonSize="large"
          disabled={!isSubmitAvailable}
          onClick={onSubmit}
        >
          {isLoading ? (
            <Loader className={styles.loader} />
          ) : (
            <span>{t("component.sidebar.dashboard_events.button.submit")}</span>
          )}
        </Button>
      </div>
    </div>
  );
};
