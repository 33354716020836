import styles from "./ForecastDivider.module.scss";

export const FORECAST_DIVIDER_LINE_PROPS = {
  className: styles.wrapper,
  opacity: 0.5,
  isFront: true,
  strokeDasharray: "10 10",
};

export const FORECAST_DIVIDER_LABEL_PROPS = {
  className: styles.label,
  offset: 6,
};
