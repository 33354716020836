import { FC, useMemo, useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./DuplicateDashboardModal.module.scss";
import { useAppDispatch } from "src/store";
import { duplicateDashboard } from "src/store/actions";
import {
  triggerGtmEvent,
  showToastNotification,
  showPromiseToastNotification,
} from "src/utils";
import {
  Form,
  Label,
  Loader,
  Switch,
  Button,
  Tooltip,
  Checkbox,
} from "src/components";
import {
  GRAY,
  GREEN,
  ROUTES,
  DASHBOARD_DEFAULT_VISIBILITY,
} from "src/constants";
import {
  selectTrackersLimit,
  selectDashboardById,
  selectDashboardsLimit,
  selectTrackersCollectionTrackers,
  selectLocationsByKeywordsDataSources,
} from "src/store/selectors";
import {
  ConfirmModal,
  LanguagesDropdown,
  LocationsDropdown,
  DashboardVisibilityDropdown,
} from "src/features";
import {
  useModal,
  useScrollTo,
  useLanguageId,
  useLocationId,
  useTrackerBlocker,
  useDashboardBlocker,
} from "src/hooks";

// Inner imports
import {
  DuplicateDashboardTracker,
  DuplicateDashboardConfiguration,
} from "./components";

type Props = {
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const DuplicateDashboardModal: FC<Props> = ({
  trackersCollectionId,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const [configurationRef, setShouldScrollTo] = useScrollTo<HTMLDivElement>({
    behavior: "smooth",
  });

  const { showDashboardLimitNotification } = useDashboardBlocker();

  const { showTrackerLimitNotification } = useTrackerBlocker();

  const dashboard = useSelector((state: Store.RootState) =>
    selectDashboardById(state, trackersCollectionId),
  );

  const { trackersLimit, trackersCount } = useSelector(selectTrackersLimit);

  const { dashboardsLimit, dashboardsCount } = useSelector(
    selectDashboardsLimit,
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersCollectionTrackers(state, trackersCollectionId),
  );

  const trackerKeywordsDataSources = useMemo<
    Search.KeywordsDataSource[]
  >(() => {
    const keywordsDataSources = new Set<Search.KeywordsDataSource>();

    for (const tracker of trackers)
      for (const dataSource of tracker.keywordsDataSources)
        keywordsDataSources.add(dataSource);

    return Array.from(keywordsDataSources);
  }, [trackers]);

  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSources(state, trackerKeywordsDataSources),
  );

  const [locationId, setLocationId] = useLocationId({
    keywordsDataSources: trackerKeywordsDataSources,
  });

  const [languageId, setLanguageId] = useLanguageId({
    locationId,
    keywordsDataSources: trackerKeywordsDataSources,
  });

  const defaultSelectedTrackerIds = useMemo<Tracker.Data["id"][]>(
    () => trackers.map(({ id }) => id),
    [trackers],
  );

  const [activeTab, setActiveTab] = useState<"duplicate" | "batchDuplicate">(
    "duplicate",
  );

  const [configurations, setConfigurations] = useState<
    Tracker.DuplicateConfig[]
  >([]);

  const [selectedTrackerIds, setSelectedTrackerIds] = useState<
    Tracker.Data["id"][]
  >(defaultSelectedTrackerIds);

  const [visibility, setVisibility] = useState<Nullable<Dashboard.Visibility>>(
    dashboard?.visibility || DASHBOARD_DEFAULT_VISIBILITY,
  );

  const [shouldCopyKeywords, setShouldCopyKeywords] = useState<boolean>(false);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isBatchDuplicate = useMemo<boolean>(
    () => activeTab === "batchDuplicate",
    [activeTab],
  );

  const isAddAvailable = useMemo<boolean>(
    () => Boolean(locationId) && Boolean(languageId),
    [locationId, languageId],
  );

  const hasConfigurations = useMemo<boolean>(
    () => Boolean(configurations.length),
    [configurations.length],
  );

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isAllTrackerSelected = useMemo<boolean>(
    () => defaultSelectedTrackerIds.length === selectedTrackerIds.length,
    [defaultSelectedTrackerIds.length, selectedTrackerIds.length],
  );

  const isSubmitAvailable = useMemo<boolean>(() => {
    if (!selectedTrackerIds.length || isLoading) return false;

    if (isBatchDuplicate) return hasConfigurations;

    return true;
  }, [
    isLoading,
    isBatchDuplicate,
    hasConfigurations,
    selectedTrackerIds.length,
  ]);

  const submitLabel = useMemo<string>(
    () =>
      isBatchDuplicate
        ? t(
            "component.modal.duplicate_dashboard.button.batch_duplicate_dashboard",
          )
        : t("component.modal.duplicate_dashboard.button.duplicate_dashboard"),
    [isBatchDuplicate, t],
  );

  const isSelectAllDisabled = useMemo<boolean>(
    () => trackers.length <= 1,
    [trackers.length],
  );

  const isBatchDuplicateAvailable = useMemo<boolean>(
    () => locations.length > 0,
    [locations.length],
  );

  const batchDuplicateTooltip = useMemo<string>(() => {
    if (isBatchDuplicateAvailable) return "";

    return t(
      "component.modal.duplicate_dashboard.label.batch_duplicate_location_inconsistency",
    );
  }, [isBatchDuplicateAvailable, t]);

  const updateShouldCopyKeywords = (): void =>
    setShouldCopyKeywords((state) => !state);

  const addConfiguration = (
    locationId: Nullable<Location.Data["id"]>,
    languageId: Nullable<Language.Data["id"]>,
  ): void => {
    if (!locationId || !languageId) return;

    const isDashboardLimitExceeded =
      dashboardsCount + configurations.length > dashboardsLimit;

    if (isDashboardLimitExceeded) return showDashboardLimitNotification();

    const isTrackerLimitExceeded =
      trackersCount + configurations.length * selectedTrackerIds.length >
      trackersLimit;

    if (isTrackerLimitExceeded) return showTrackerLimitNotification();

    const newConfiguration = { locationId, languageId };

    const hasConfiguration = configurations.some(
      (configuration) =>
        configuration.languageId === languageId &&
        configuration.locationId === locationId,
    );

    if (hasConfiguration)
      return showToastNotification({
        id: "duplicate-dashboard-configuration",
        type: "info",
        text: t(
          "component.modal.duplicate_dashboard.status.info.configuration_exists",
        ),
      });

    setConfigurations((state) => [...state, newConfiguration]);

    setLocationId(null);

    setLanguageId(null);

    setShouldScrollTo(true);
  };

  const toggleSelectTrackerId = (trackerId: Tracker.Data["id"]): void => {
    const isSelected = selectedTrackerIds.includes(trackerId);

    if (isSelected)
      setSelectedTrackerIds((state) => state.filter((id) => id !== trackerId));
    else setSelectedTrackerIds((state) => [...state, trackerId]);
  };

  const toggleSelectAllTrackerIds = (): void => {
    if (isAllTrackerSelected) setSelectedTrackerIds([]);
    else setSelectedTrackerIds(defaultSelectedTrackerIds);
  };

  const toggleActiveTab = (): void => {
    if (isBatchDuplicate) setActiveTab("duplicate");
    else setActiveTab("batchDuplicate");
  };

  const removeConfiguration = ({
    locationId,
    languageId,
  }: Tracker.DuplicateConfig): void =>
    setConfigurations((state) =>
      state.filter(
        (configuration) =>
          configuration.locationId !== locationId ||
          configuration.languageId !== languageId,
      ),
    );

  const onSubmit = async (): Promise<void> => {
    if (isLoading) return;

    const isDashboardLimitExceeded =
      dashboardsCount + (configurations.length || 1) > dashboardsLimit;

    if (isDashboardLimitExceeded) return showDashboardLimitNotification();

    const isTrackerLimitExceeded =
      trackersCount + (configurations.length || 1) * selectedTrackerIds.length >
      trackersLimit;

    if (isTrackerLimitExceeded) return showTrackerLimitNotification();

    const updateNotification = showPromiseToastNotification({
      text: t(
        "component.modal.duplicate_dashboard.status.loading.duplicate_dashboard",
      ),
    });

    try {
      setLoadingStatus("loading");

      const dashboards = await dispatch(
        duplicateDashboard({
          visibility: visibility || DASHBOARD_DEFAULT_VISIBILITY,
          trackerIds: selectedTrackerIds,
          dashboardId: trackersCollectionId,
          configurations: isBatchDuplicate ? configurations : [],
          shouldCopyKeywords,
        }),
      ).unwrap();

      if (dashboards.length === 1 && dashboards[0])
        history.push(`${ROUTES.dashboardsHomePage}/${dashboards[0].id}`);

      if (dashboards.length > 1)
        history.replace(history.location.pathname, {
          shouldOpenGlobalSearch: true,
        });

      updateNotification({
        type: "success",
        text: t(
          "component.modal.duplicate_dashboard.status.success.duplicate_dashboard",
        ),
      });

      setLoadingStatus("succeeded");

      triggerGtmEvent("DashboardDuplicate", {
        dashboardId: trackersCollectionId,
      });

      closeModal("duplicate-dashboard");
    } catch (error) {
      console.error(error);

      setLoadingStatus("failed");

      updateNotification({
        type: "error",
        text: t(
          "component.modal.duplicate_dashboard.status.error.duplicate_dashboard",
        ),
      });
    }
  };

  return (
    <ConfirmModal
      id="duplicate-dashboard"
      type="success"
      title={t("component.modal.duplicate_dashboard.title")}
      className={styles.wrapper}
    >
      <Form onSubmit={onSubmit} className={styles.form}>
        <div className={styles.tabsWrapper}>
          <Button
            onClick={toggleActiveTab}
            buttonStyle="transparent"
            className={cx(styles.tab, isBatchDuplicate ? "" : styles.active)}
          >
            {t("component.modal.duplicate_dashboard.label.duplicate")}
          </Button>
          <Button
            onClick={toggleActiveTab}
            className={cx(styles.tab, isBatchDuplicate ? styles.active : "")}
            disabled={!isBatchDuplicateAvailable}
            buttonStyle="transparent"
          >
            <Tooltip content={batchDuplicateTooltip}>
              {t("component.modal.duplicate_dashboard.label.batch_duplicate")}
            </Tooltip>
          </Button>
        </div>
        <div className={styles.trackersWrapper}>
          <div className={styles.trackersTitle}>
            <span>
              {t("component.modal.duplicate_dashboard.label.included_topics", {
                count: trackers.length,
              })}
            </span>
            <div className={styles.selectWrapper}>
              <Checkbox
                color={GREEN}
                isDisabled={isSelectAllDisabled}
                isChecked={isAllTrackerSelected}
                onClick={toggleSelectAllTrackerIds}
              />
              <span>
                {t("component.modal.duplicate_dashboard.label.include_all")}
              </span>
            </div>
          </div>
          <div className={styles.trackers}>
            {trackers.map(({ id }) => (
              <DuplicateDashboardTracker
                key={id}
                trackerId={id}
                trackers={trackers}
                selectedTrackerIds={selectedTrackerIds}
                toggleSelectTrackerIdHandler={toggleSelectTrackerId}
              />
            ))}
          </div>
        </div>
        <div className={styles.divider} />
        {isBatchDuplicate && (
          <>
            <div className={styles.settingsWrapper}>
              <span className={styles.settingsTitle}>
                {t(
                  "component.modal.duplicate_dashboard.label.duplicate_settings",
                )}
              </span>
              <div className={styles.settings}>
                <div className={styles.setting}>
                  <Label
                    className={styles.label}
                    leftText={t(
                      "component.modal.duplicate_dashboard.label.location",
                    )}
                  />
                  <LocationsDropdown
                    locationId={locationId}
                    setLocationId={setLocationId}
                    keywordsDataSources={trackerKeywordsDataSources}
                  />
                </div>
                <div className={styles.setting}>
                  <Label
                    className={styles.label}
                    leftText={t(
                      "component.modal.duplicate_dashboard.label.language",
                    )}
                  />
                  <LanguagesDropdown
                    languageId={languageId}
                    setLanguageId={setLanguageId}
                    keywordsDataSources={trackerKeywordsDataSources}
                  />
                </div>
                <div>
                  <Button
                    onClick={() => addConfiguration(locationId, languageId)}
                    disabled={!isAddAvailable}
                    className={styles.button}
                  >
                    {t(
                      "component.modal.duplicate_dashboard.button.add_configuration",
                    )}
                  </Button>
                </div>
              </div>
              {hasConfigurations && (
                <div className={styles.configurations}>
                  {configurations.map((configuration, index) => (
                    <DuplicateDashboardConfiguration
                      ref={configurationRef}
                      key={`${locationId}-${languageId}-${index}`}
                      configuration={configuration}
                      removeConfigurationHandler={removeConfiguration}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className={styles.divider} />
          </>
        )}
        <div className={styles.submit}>
          <div className={styles.keywordsSettings}>
            <div className={styles.preserveKeywords}>
              <Switch
                onColor={GREEN}
                checked={shouldCopyKeywords}
                offColor={GRAY}
                disabled={!isSubmitAvailable}
                onChange={updateShouldCopyKeywords}
              />
              <span>
                {t(
                  "component.modal.duplicate_dashboard.label.should_copy_keywords",
                )}
              </span>
            </div>
            <DashboardVisibilityDropdown
              visibility={visibility}
              searchable={false}
              setVisibility={setVisibility}
            />
          </div>
          <Button
            type="submit"
            disabled={!isSubmitAvailable}
            className={styles.submitButton}
          >
            {isLoading && <Loader />}
            <span>{submitLabel}</span>
          </Button>
        </div>
      </Form>
    </ConfirmModal>
  );
};
