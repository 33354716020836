import { useState, FC, MouseEvent, PropsWithChildren } from "react";
import cx from "classnames";

import styles from "./Tooltip.module.scss";
import { TooltipPortal } from "src/features";

// Inner imports
import { TOOLTIP_STYLE } from "./constants";
import { calculateTooltipCoordinates } from "./utils";

type Props = PropsWithChildren & {
  content: string | JSX.Element;
  className?: string;
};

export const Tooltip: FC<Props> = ({ content, className, children }) => {
  const [coordinates, setCoordinates] = useState<Coordinates>({ x: 0, y: 0 });

  const [isTooltipShown, setIsTooltipShown] = useState<boolean>(false);

  const onMouseEnter = (e: MouseEvent<HTMLDivElement>): void => {
    if (!content) return;

    const newCoordinates = calculateTooltipCoordinates(
      e.currentTarget,
      content,
    );

    setCoordinates(newCoordinates);

    setIsTooltipShown(true);
  };

  const onMouseLeave = (): void => setIsTooltipShown(false);

  const onMouseDown = (): void => setIsTooltipShown(false);

  return (
    <div
      className={cx(styles.wrapper, className)}
      onMouseEnter={onMouseEnter}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
    >
      {children}
      {isTooltipShown && (
        <TooltipPortal>
          <div
            style={{
              ...TOOLTIP_STYLE,
              left: coordinates.x,
              top: coordinates.y,
            }}
            className={styles.tooltip}
          >
            {content}
          </div>
        </TooltipPortal>
      )}
    </div>
  );
};
