import { useEffect } from "react";

export const useWindowHeight = () => {
  // It has been needed for replace 100vh property on css.
  // It's allow us to calculate real screen height.
  // And fix screens height on Safari browser or IOS devices.

  useEffect(() => {
    function appHeight(this: any) {
      const currentWindowHeight = this.__proto__.currentWindowHeight;
      const doc = document.documentElement;
      const windowHeight = window.innerHeight;

      if (
        typeof currentWindowHeight === "undefined" ||
        currentWindowHeight !== windowHeight
      ) {
        doc.style.setProperty("--app-height", `${windowHeight}px`);
        this.__proto__.currentWindowHeight = windowHeight;
      }
    }
    appHeight.call(appHeight);

    window.addEventListener("resize", appHeight);
    window.addEventListener("orientationchange", appHeight);
  }, []);
};
